import React from 'react';
import { Box, Form, FormExtendedEvent, Heading, Select, Text, TextArea } from 'grommet';
import { Checkbox, Image, Input, RadioButtonGroup } from 'tutadoo-design-system/src/components';
import { Button } from 'tutadoo-design-system/src/components/Button';
import placeholderImg from '../../../assets/placeholder.png';
import AutoBoxMinH from 'components/AutoBoxMinH';

export interface IFormProps {
  isEdit?: boolean;
  initialValues?: any;
  onChange?: () => void;
  onSubmit: (event: FormExtendedEvent<any, Element>) => void;
  onClose?: () => void;
  children?: React.ReactNode;
  title?: string;
  description?: string;
  icon?: React.ReactNode;
}

import tw from 'twin.macro';

export const Divider = tw.div`w-full mx-auto h-0 my-2 border border-gray-200`;

function AddOnForm({
  nameSpace,
  quantityNamespace,
  salesEndNamespace
}: {
  nameSpace: string;
  quantityNamespace?: string;
  salesEndNamespace?: string;
}) {
  return (
    <Box>
      <Input label={`${nameSpace} name`} inputProps={{ placeholder: `${nameSpace} Name` }} />
      <Box margin={{ top: '24px' }}>
        <Text as="label" size="small" weight={'bold'} color="#62656D">
          {`${nameSpace} description`}
          <Text style={{ fontStyle: 'italic' }} size="small" weight={'normal'} as="span">
            (Optional)
          </Text>
        </Text>
        <TextArea />
        <Text textAlign="end">0/100</Text>
      </Box>
      <Box margin={{ top: '24px' }}>
        <Input label={`${quantityNamespace} quantity`} inputProps={{ placeholder: '0' }} />
        <Checkbox label="Unlimited" />
      </Box>

      <Box margin={{ top: '24px' }}>
        <Input label={`${nameSpace} Price`} inputProps={{ placeholder: 'KES 0.00' }} />
      </Box>

      <Box align="center" direction="row" margin={{ top: '24px' }}>
        <Input
          margin={{ right: '16px' }}
          label={`${salesEndNamespace} sales end`}
          inputProps={{ placeholder: 'KES 0.01' }}
        />
        <Box margin={{ top: '30px' }}>
          <Select options={['Hour(s)']} value="Hour(s)" />
        </Box>
      </Box>
      <Box margin={{ top: '10px' }}>
        <RadioButtonGroup
          name="salesEnd.at"
          options={[
            { label: 'Before event starts', value: 'before-start' },
            { label: 'Before event ends', value: 'before-end' }
          ]}
        />
      </Box>
      <Divider style={{ margin: '24px 0px' }} />
    </Box>
  );
}

export function FormWrapper({
  initialValues,
  onSubmit,
  children,
  onChange,
  onClose,
  title,
  description,
  icon
}: IFormProps) {
  return (
    <Form onChange={onChange} value={initialValues} onSubmit={onSubmit}>
      <Box flex height={{ max: '80vh' }} overflow="auto" pad="0 32px">
        <AutoBoxMinH>
          <Heading style={{ display: 'flex', alignItems: 'center' }} level="3">
            {icon && <Box as="span">{icon}</Box>}
            {title}
          </Heading>
          <Text margin={{ top: '16px' }}>{description}</Text>
          {children}
        </AutoBoxMinH>
      </Box>
      <Box
        as="footer"
        border={{ side: 'top' }}
        pad="small"
        justify="center"
        direction="row"
        align="center"
      >
        <Button
          margin={{ right: '16px' }}
          style={{ width: '50%', background: '#F5F5F6', color: '#000' }}
          primary
          label="Cancel"
          onClick={onClose}
        />
        <Button style={{ width: '50%' }} primary label={'Save'} />
      </Box>
    </Form>
  );
}

export function ImageBox() {
  return (
    <Box margin={'10px 0px'} direction="row" align="center">
      <Box margin={{ right: '10px' }}>
        <Image width={'112px'} height={'112px'} src={placeholderImg} />
      </Box>
      <Box>
        <Button primary label="Upload cover image" />
      </Box>
    </Box>
  );
}
AddOnForm.propTypes = {};

export default AddOnForm;
