import React from 'react';
import StatusCard from 'components/StatusCard';
import { Box, Button } from 'grommet';
import useDisclosure from '../../hooks/useDisclosure';
import noAddonImg from '../../assets/noAddon.png';
import AddonDrawer from './AddonDrawers/AddonDrawer';

function AddonList({ eventId }: { eventId: string }) {
  const { isOpen, onClose, onOpen } = useDisclosure({});
  const [selected, setSelected] = React.useState(null);

  const handleOnClose = () => {
    if (selected) setSelected(null);
    onClose();
  };
  const tickets = [];
  return (
    <Box>
      {!!!tickets?.length ? (
        <StatusCard
          image={noAddonImg}
          mainText="Anything else?"
          subText="Sell additional items for your event - like parking,
          merchandise, or campsites."
        >
          <Button
            style={{ height: '48px' }}
            margin={{ top: '24px' }}
            primary
            label="Add add-ons"
            onClick={onOpen}
          />
        </StatusCard>
      ) : (
        <Box>LIST OF CARDS</Box>
      )}
      <AddonDrawer
        isOpen={isOpen}
        onClose={handleOnClose}
        // onChange={selected ? handleChange : undefined}
      />
    </Box>
  );
}

AddonList.propTypes = {};

export default AddonList;
