import React, { SVGProps } from 'react';

const RecurringEventIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.99996 4.9997H14V6.7897C14 7.2397 14.54 7.4597 14.85 7.1397L17.64 4.3497C17.84 4.1497 17.84 3.8397 17.64 3.6397L14.85 0.849696C14.54 0.539696 14 0.759697 14 1.2097V2.9997H2.99996C2.44996 2.9997 1.99996 3.4497 1.99996 3.9997V7.9997C1.99996 8.5497 2.44996 8.9997 2.99996 8.9997C3.54996 8.9997 3.99996 8.5497 3.99996 7.9997V4.9997ZM14 14.9997H3.99996V13.2097C3.99996 12.7597 3.45996 12.5397 3.14996 12.8597L0.359961 15.6497C0.159961 15.8497 0.159961 16.1597 0.359961 16.3597L3.14996 19.1497C3.45996 19.4597 3.99996 19.2397 3.99996 18.7897V16.9997H15C15.55 16.9997 16 16.5497 16 15.9997V11.9997C16 11.4497 15.55 10.9997 15 10.9997C14.45 10.9997 14 11.4497 14 11.9997V14.9997Z"
      fill="#62656D"
    />
  </svg>
);

export default RecurringEventIcon;
