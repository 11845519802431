import React from 'react';
import { Router } from '@reach/router';
import OrganizationEventDashboard from 'components/OrganizationDashboard/OrganizationEventDashboard/OrganizationEventDashboard';

const basepath = 'organization/events/dashboard';
function dashboard() {
  return (
    <Router basepath={basepath}>
      <OrganizationEventDashboard basepath={basepath} path=":eventId" />
      <OrganizationEventDashboard basepath={basepath} path=":eventId/:tab" />
    </Router>
  );
}

dashboard.propTypes = {};

export default dashboard;
