import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { ITicketType, ITicketDistribution } from 'services/events';
import { styled } from 'twin.macro';
import { DotBoxIcon, CommentIcon } from 'components/icons';
import { Button } from 'tutadoo-design-system/src/components/Button';

const TicketCardContainer = styled(Box)`
  min-height: 192px;
  border: 1px solid #ebeced;
  border-radius: 8px;
  padding: 24px;
  display: grid;
  grid-template-columns: 48px 2fr 1fr 1fr 48px;
  align-items: center;
  .quantity,
  .price {
    text-align: right;
  }
`;

function TicketCard({
  ticket,
  distributions
}: {
  ticket: ITicketType;
  distributions?: ITicketDistribution[];
}) {
  return (
    <TicketCardContainer>
      <Box>
        <DotBoxIcon />
      </Box>
      <Box>
        <Heading level={'5'}>{ticket.name}</Heading>
        <Text>{ticket?.description}</Text>
        <Text>
          <CommentIcon /> Attached {ticket.questions.length} custom questions{' '}
        </Text>
        <Box>
          <Button style={{ background: '#E4FCFA', borderRadius: '100px' }} label={ticket.status} />
          <Text>Ends {ticket.sales_end} hour before event starts</Text>
        </Box>
      </Box>
      <Box className="quantity">{distributions?.reduce((n, { count }) => n + count, 0)}</Box>
      <Box className="price">{ticket?.price}</Box>
    </TicketCardContainer>
  );
}

TicketCard.propTypes = {};

export default TicketCard;
