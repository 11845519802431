import React from 'react';
import {
  Box,
  CheckBox,
  Form,
  FormExtendedEvent,
  Heading,
  Layer,
  Select,
  Text,
  TextInput,
  TextArea
} from 'grommet';
import { CloseIcon, PlusIcon, TrashIcon } from 'components/icons';
import BoxSelect from 'components/BoxSelect';
import { Button } from 'tutadoo-design-system/src/components/Button';
import { Checkbox, Input, RadioButtonGroup } from 'tutadoo-design-system/src/components';
import AutoBoxMinH from 'components/AutoBoxMinH';
import tw, { styled } from 'twin.macro';
import useDisclosure from '../../hooks/useDisclosure';
import { IconButton } from 'grommet-controls';

const Divider = tw.div`w-full mx-auto h-0 my-2 border border-gray-200`;
const CheckBoxWrap = styled.div`
  margin: 10px 0px;
  label {
    justify-content: space-between;
  }
`;

interface ITicket {
  name: string;
  questions: string[];
  description: string[];
  quantity: number;
  price: number;
  salesEnd: {
    duration: number;
    period: string;
    at: 'before-start' | 'before-end';
  };
  ticketsPerOrder: {
    min: number;
    max: number;
  };
}

function CreateTicketsDrawer({
  onClose,
  isOpen,
  initialValues,
  onSubmit,
  onChange
}: {
  onClose?: () => void;
  isOpen: boolean;
  isEdit?: boolean;
  initialValues: ITicket;
  onChange?: () => void;
  onSubmit: (event: FormExtendedEvent<ITicket, Element>) => void;
}) {
  const {
    isOpen: advanceIsVisible,
    onOpen: openAdvance,
    onClose: closeAdvance
  } = useDisclosure({});

  const handleAdvanceToggle = (shouldOpen: boolean) =>
    shouldOpen ? openAdvance() : closeAdvance();
  const [questions, setQuestions] = React.useState<string[]>([]);
  const [ticketType, setTicketType] = React.useState('Paid');

  const handleQuestionsChange = (value, index) => {
    const clone = [...questions];
    clone[index] = value;
    setQuestions(clone);
  };

  const removeIndex = (index) => {
    const clone = [...questions];
    clone.splice(index, 1);
    setQuestions(clone);
  };

  const isFree = ticketType === 'Free';
  const isDonation = ticketType === 'Donation';

  return isOpen ? (
    <Layer full="vertical" position="right">
      <Form onChange={onChange} value={initialValues} onSubmit={onSubmit}>
        <Box fill style={{ minWidth: '480px' }}>
          <Box direction="row" align="center" as="header" height={{ min: '56px' }} justify="end">
            <Button style={{ background: 'transparent' }} onClick={onClose} icon={<CloseIcon />} />
          </Box>
          <Box flex height={{ max: '80vh' }} overflow="auto" pad="0 32px">
            <AutoBoxMinH>
              <Heading level="3">Add ticket</Heading>
              <Text margin={{ top: '16px' }}>
                Let your attendees get a seat to your event by providing a ticket.
              </Text>
              <BoxSelect
                onChange={(e) => setTicketType(e)}
                width="100%"
                value={ticketType}
                options={['Paid', 'Free', 'Donation']}
              />
              <Input label={'Ticket name'} inputProps={{ placeholder: 'Ticket name' }} />
              <Box margin={{ top: '24px' }}>
                <Text as="label" size="small" weight={'bold'} color="#62656D">
                  Ticket description{' '}
                  <Text style={{ fontStyle: 'italic' }} size="small" weight={'normal'} as="span">
                    (Optional)
                  </Text>
                </Text>
                <TextArea />
                <Text textAlign="end">0/100</Text>
              </Box>
              <Box margin={{ top: '24px' }}>
                <Input label={'Available quantity'} inputProps={{ placeholder: '0' }} />
                <Checkbox label="Unlimited" />
              </Box>
              {!isFree && (
                <Box margin={{ top: '24px' }}>
                  <Input
                    label={'Price'}
                    inputProps={{ placeholder: 'KES 0.00', disabled: isDonation }}
                  />
                  {isDonation && <Text>Attendees can donate what they wish</Text>}
                </Box>
              )}
              <Box align="center" direction="row" margin={{ top: '24px' }}>
                <Input
                  margin={{ right: '16px' }}
                  label={'Ticket sales end'}
                  inputProps={{ placeholder: 'KES 0.01' }}
                />
                <Box margin={{ top: '30px' }}>
                  <Select options={['Hour(s)']} value="Hour(s)" />
                </Box>
              </Box>
              <Box margin={{ top: '10px' }}>
                <RadioButtonGroup
                  name="salesEnd.at"
                  options={[
                    { label: 'Before event starts', value: 'before-start' },
                    { label: 'Before event ends', value: 'before-end' }
                  ]}
                />
              </Box>
              <Divider style={{ margin: '24px 0px' }} />
              <CheckBoxWrap>
                <CheckBox
                  checked={advanceIsVisible}
                  reverse
                  toggle
                  label="Advance Issues"
                  onChange={(event) => handleAdvanceToggle(event.target.checked)}
                />
              </CheckBoxWrap>
              {advanceIsVisible && (
                <Box>
                  <Box direction="row">
                    <Box width={'50%'}>
                      <Input label="Min tickets per order" margin={{ right: '16px' }} />
                    </Box>
                    <Box width={'50%'}>
                      <Input label="Max tickets per order" />
                    </Box>
                  </Box>
                  <Box margin={{ top: '24px' }}>
                    <Text color="#62656D" weight={'bold'} size="small">
                      Custom questions
                    </Text>
                    <Text size="small" color="#62656D" margin={'8px 0px'}>
                      Add custom questions for attendees to collect more information
                    </Text>
                    <Input inputProps={{ placeholder: 'Enter the question' }} />
                    {questions?.map((qs, i) => (
                      <Box
                        align="center"
                        direction="row"
                        key={`question_${i}_${qs}`}
                        margin={{ top: '24px' }}
                      >
                        <TextInput
                          onChange={(e) => handleQuestionsChange(e.target.value, i)}
                          placeholder="Enter the question"
                        />
                        <IconButton
                          style={{
                            height: '48px',
                            width: '48px',
                            flexShrink: 0,
                            padding: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '10px'
                          }}
                          onClick={() => removeIndex(i)}
                          icon={<TrashIcon />}
                        />
                      </Box>
                    ))}

                    <Button
                      plain
                      color={'purple.800'}
                      icon={<PlusIcon />}
                      label="Add more question"
                      justify="start"
                      onClick={() => setQuestions([...questions, ''])}
                      margin="20px 0"
                    />
                  </Box>
                </Box>
              )}
            </AutoBoxMinH>
          </Box>
          <Box
            as="footer"
            border={{ side: 'top' }}
            pad="small"
            justify="center"
            direction="row"
            align="center"
          >
            <Button
              margin={{ right: '16px' }}
              style={{ width: '50%', background: '#F5F5F6', color: '#000' }}
              primary
              label="Cancel"
              onClick={onClose}
            />
            <Button style={{ width: '50%' }} primary label={'Save'} />
          </Box>
        </Box>
      </Form>
    </Layer>
  ) : null;
}

CreateTicketsDrawer.propTypes = {};

export default CreateTicketsDrawer;
