import { useMutation } from '@apollo/client';
import {
  SingleEventIcon,
  MultipleEventIcon,
  RecurringEventIcon,
  PencilIcon,
  EyeIcon,
  ChevronDownIcon
} from 'components/icons';
import { Box, Heading, Text, Image, DropButton, Spinner } from 'grommet';
import useToast from '../hooks/useToast';
import moment from 'moment';
import React from 'react';
import { EventType, IEvent, EventStatusType, Venue, EventsService } from 'services/events';
import { Button } from 'tutadoo-design-system/src/components/Button';

const eventTypeRecord: Record<EventType, any> = {
  single: {
    label: 'Single event',
    icon: SingleEventIcon
  },
  multi: {
    label: 'Multiply event',
    icon: MultipleEventIcon
  },
  recurring: {
    label: 'Recurring event',
    icon: RecurringEventIcon
  }
};

function EventDashboardHeader({ event }: { event: IEvent }) {
  const { name, status, start_date, end_date, event_type, venues, address, image_url } = event;
  return (
    <Box direction="row" justify="between" align="center">
      <Box>
        <HeaderDetails
          name={name}
          address={address}
          venues={venues}
          start_date={start_date}
          end_date={end_date}
          type={event_type}
        />
        <HeaderActions status={status} eventId={event?.id} />
      </Box>
      <Box>
        <Image src={image_url} style={{ width: '100%', maxWidth: '400px', maxHeight: '200px' }} />
      </Box>
    </Box>
  );
}

const EventTypeTag = ({ type }: { type: EventType }) => {
  const { icon: Icon, label } = eventTypeRecord[type || 'single'];
  return (
    <Button margin={'20px 0px'} alignSelf="start" size="medium" label={label} icon={<Icon />} />
  );
};

const HeaderDetails = ({
  name,
  type,
  venues,
  address,
  start_date,
  end_date
}: {
  name: string;
  type: EventType;
  address: string;
  venues: Venue[];
  start_date: string;
  end_date: string;
}) => {
  const locationCount = venues?.length || 0;
  return (
    <Box>
      <EventTypeTag type={type} />
      <Heading level={'3'}>{name}</Heading>
      <Text margin={{ top: '8px' }}>
        {moment(start_date).format('LL h:mma')}
        {type === 'recurring' && `-${moment(end_date).format('LL h:mma')}`}
      </Text>
      <Text margin={{ top: '4px' }}>
        {locationCount > 1 ? (
          <Text weight={'bold'} color="#512E8D" as="span">
            {locationCount} locations
          </Text>
        ) : (
          address
        )}{' '}
        Nairobi Kenya
      </Text>
    </Box>
  );
};

const HeaderActions = ({ status, eventId }: { status: EventStatusType; eventId: string }) => {
  const options = ['private', 'unpublished', 'published'];
  const [updateEventStatus, { loading }] = useMutation<
    { event: IEvent },
    { status: EventStatusType }
  >(EventsService.setEventStatus(eventId));
  const { renderToast, toast } = useToast();

  const handleEventStatusUpdate = (opt: EventStatusType) => {
    updateEventStatus({
      variables: {
        status: opt
      },
      onError: (err) => toast.error(err?.message, 'Failed to update event status'),
      onCompleted: (res) => toast.success(`event ${res?.event?.name} status updated`)
    });
  };

  return (
    <Box margin={'24px 0px'} direction="row">
      <DropButton
        label={
          <Text className='flex'>
            {status} {loading && <Spinner color={"#fff"} />}{' '}
          </Text>
        }
        dropAlign={{ top: 'bottom' }}
        dropContent={
          <Box pad="small" background="#fff">
            {options.map((opt: EventStatusType) => (
              <Text
                onClick={() => handleEventStatusUpdate(opt)}
                style={{ padding: '10px', textTransform: 'capitalize' }}
                key={opt}
              >
                {opt}
              </Text>
            ))}
          </Box>
        }
        {...(['published', 'private']?.includes(status?.toLowerCase())
          ? { primary: true }
          : { secondary: true })}
        icon={<ChevronDownIcon color="#fff" />}
        style={{ textTransform: 'capitalize' }}
        reverse
        margin={{ right: '10px' }}
      />

      <Button secondary label="Preview event" icon={<EyeIcon />} margin={{ right: '10px' }} />
      <Button secondary label="Edit event" icon={<PencilIcon />} margin={{ right: '10px' }} />
      {renderToast()}
    </Box>
  );
};

EventDashboardHeader.propTypes = {};

export default EventDashboardHeader;
