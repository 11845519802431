import AddOnForm from './AddOnForm';
import React from 'react';

function CreateSimpleAdon() {
  return <div>
      <AddOnForm nameSpace='Add-on' quantityNamespace='Available' salesEndNamespace='Tickets' />
  </div>;
}

CreateSimpleAdon.propTypes = {};

export default CreateSimpleAdon;
