import { Divider, ImageBox } from './AddOnForm';
import { Text } from 'grommet';
import React from 'react';
import { Input } from 'tutadoo-design-system/src/components';
import { Button } from 'tutadoo-design-system/src/components/Button';
import { PlusIcon } from 'components/icons';
import AddVariation from './AddVariation';
import useDisclosure from '../../../hooks/useDisclosure';

function CreateVariantAdon() {
  const { isOpen, onOpen, onClose } = useDisclosure({});

  return (
    <div>
      <Input label={`Add-on name`} inputProps={{ placeholder: `Add-on Name` }} />
      <Text as="label" size="small" weight={'bold'} color="#62656D">
        Add-on image
        <Text style={{ fontStyle: 'italic' }} size="small" weight={'normal'} as="span">
          (Optional)
        </Text>
      </Text>
      <Text as="p" size="small">
        JPG, or PNG. Required size 2MB or less.
      </Text>
      <ImageBox />
      <Divider style={{ margin: '24px 0px' }} />
      <Text as="label" size="small" weight={'bold'} color="#62656D">
        Variations
      </Text>
      <Text as="p">These will share the total quantity</Text>
      <Button
        plain
        color={'purple.800'}
        icon={<PlusIcon />}
        label="Add variation"
        justify="start"
        onClick={onOpen}
        margin="20px 0"
      />
      <AddVariation isOpen={isOpen} onClose={onClose} onSubmit={() => {}} />
    </div>
  );
}

CreateVariantAdon.propTypes = {};

export default CreateVariantAdon;
