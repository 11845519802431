import React, { SVGProps } from 'react';

const CommentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    color="#62656D"
    {...props}
  >
    <path
      d="M15.6667 4.00008H14.8334V10.6667C14.8334 11.1251 14.4584 11.5001 14 11.5001H4.00002V12.3334C4.00002 13.2501 4.75002 14.0001 5.66669 14.0001H14L17.3334 17.3334V5.66675C17.3334 4.75008 16.5834 4.00008 15.6667 4.00008ZM13.1667 8.16675V2.33341C13.1667 1.41675 12.4167 0.666748 11.5 0.666748H2.33335C1.41669 0.666748 0.666687 1.41675 0.666687 2.33341V13.1667L4.00002 9.83342H11.5C12.4167 9.83342 13.1667 9.08342 13.1667 8.16675Z"
      fill="currentColor"
    />
  </svg>
);

export default CommentIcon;
