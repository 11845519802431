import { useQuery } from '@apollo/client';
import EventDashboardHeader from 'components/EventDashboardHeader';
import UserDashboard from 'components/DashboardLayout/UserDashboard';
import { Box, Tab, Tabs, Text } from 'grommet';
import EventAnimationn from 'components/EventAnimation';
import EmptyState from 'components/EmptyState';
import EventOverview from 'components/EventOverview';
import TicketList from 'components/EventTickets/TicketList';
import AddonList from 'components/EventAddons/AddonLists';
import { IEvent } from 'services/events';
import { navigate, RouteComponentProps } from '@reach/router';
import { EventsService } from 'services/events';
import { useEffect } from 'react';

function OrganizationEventDashboard({
  eventId,
  tab,
  basepath
}: RouteComponentProps<{ eventId: string; tab: string; basepath: string }>) {
  const { data, loading } = useQuery<{ event: IEvent }>(EventsService.getEvent(eventId, true));
  const isLoading = loading && !!!data?.event;

  const PlaceHolder = ({ title }) => (
    <Box>
      <Text>{title}</Text>
    </Box>
  );

  const TabItems = {
    overview: {
      label: 'Event overview',
      component: <EventOverview event={data?.event} />
    },
    tickets: {
      label: 'Tickets',
      component: <TicketList eventId={eventId} />
    },
    addons: {
      label: 'Add-ons',
      component: <AddonList eventId={eventId} />
    },
    promocodes: {
      label: 'PromoCodes',
      component: <PlaceHolder title={'Promo Codes'} />
    }
  };

  const getIndex = () =>
    Object.keys(TabItems).findIndex((tabName) => tab?.toLowerCase() === tabName.toLowerCase());
  const tabIndex = getIndex();

  useEffect(() => {
    if (typeof tab === 'undefined') {
      navigate(`/${basepath}/${eventId}/overview`);
    }
  }, [tab]);

  return (
    <UserDashboard>
      <Box width={{ width: '100%', max: '1000px' }} margin={'0 auto'}>
        {isLoading ? (
          <EventAnimationn />
        ) : (
          <Box>{data?.event ? <EventDashboardHeader event={data?.event} /> : <EmptyState />}</Box>
        )}
        <Tabs activeIndex={tabIndex} className="" alignControls="start">
          {Object.keys(TabItems).map((tabKey) => {
            const { label, component } = TabItems[tabKey];

            return (
              <Tab
                className="text-gray-700 text-base font-semibold leading-[24px] m-4"
                //plain={true}
                key={tabKey}
                title={label}
                onClick={() => navigate(`/${basepath}/${eventId}/${tabKey}`)}
              >
                {component}
              </Tab>
            );
          })}
        </Tabs>
      </Box>
    </UserDashboard>
  );
}

export default OrganizationEventDashboard;
