import CreateSimpleAdon from './CreateSimpleAdon';
import CreateVariantAdon from './CreateVariantAdon';
import { CloseIcon } from 'components/icons';
import { Box, CheckBox, Layer } from 'grommet';
import React from 'react';
import { Button } from 'tutadoo-design-system/src/components/Button';
import { styled } from 'twin.macro';
import useDisclosure from '../../../hooks/useDisclosure';
import { FormWrapper } from './AddOnForm';

const CheckBoxWrap = styled.div`
  margin: 10px 0px;
  label {
    justify-content: space-between;
  }
`;

function AddonDrawer({ onClose, isOpen }: { onClose?: () => void; isOpen: boolean }) {
  const { isOpen: isVariant, onOpen: switchToVariant, onClose: switchToSingle } = useDisclosure({});

  const handleAdvanceToggle = (shouldSwitch: boolean) =>
    shouldSwitch ? switchToVariant() : switchToSingle();

  const FormToggler = () => (
    <CheckBoxWrap>
      <CheckBox
        checked={isVariant}
        reverse
        toggle
        label="This add-on has multiple prices or varieties"
        onChange={(event) => handleAdvanceToggle(event.target.checked)}
      />
    </CheckBoxWrap>
  );

  return isOpen ? (
    <Layer full="vertical" position="right">
      <Box fill style={{ width:"100%", maxWidth:"480px" }}>
        <Box direction="row" align="center" as="header" height={{ min: '56px' }} justify="end">
          <Button style={{ background: 'transparent' }} onClick={onClose} icon={<CloseIcon />} />
        </Box>
        {isVariant ? (
          <FormWrapper
            onSubmit={() => {}}
            onClose={onClose}
            title="Add add-on"
            description="Sell additional items for your event - like parking,
          merchandise, or campsites."
          >
            <FormToggler />
            <CreateVariantAdon />
          </FormWrapper>
        ) : (
          <FormWrapper
            onSubmit={() => {}}
            onClose={onClose}
            title="Add add-on"
            description="Sell additional items for your event - like parking,
          merchandise, or campsites."
          >
            <FormToggler />
            <CreateSimpleAdon />
          </FormWrapper>
        )}
      </Box>
    </Layer>
  ) : null;
}

AddonDrawer.propTypes = {};

export default AddonDrawer;
