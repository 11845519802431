import { ArrowBackIcon } from 'components/icons';
import { CloseIcon } from 'components/icons';
import { Box, Layer } from 'grommet';
import { IconButton } from 'grommet-controls';
import React from 'react';
import { Button } from 'tutadoo-design-system/src/components/Button';
import AddOnForm, { FormWrapper } from './AddOnForm';

function AddVariation({
  onClose,
  isOpen,
  onSubmit
}: {
  onClose?: () => void;
  isOpen: boolean;
  onSubmit: () => void;
}) {
  return isOpen ? (
    <Layer full="vertical" position="right">
      <Box fill style={{ width: '100%', maxWidth: '480px' }}>
        <Box direction="row" align="center" as="header" height={{ min: '56px' }} justify="end">
          <Button style={{ background: 'transparent' }} onClick={onClose} icon={<CloseIcon />} />
        </Box>
        <FormWrapper
          onSubmit={onSubmit}
          icon={
            <IconButton
              style={{
                flexShrink: 0,
                height: '40px',
                width: '40px',
                display: 'flex',
                justifyContent: 'center',
                padding:"0px",
                marginRight:"10px"
              }}
              onClick={onClose}
              icon={<ArrowBackIcon />}
            />
          }
          title="Add variation"
          description="Setup the add-on variation. It can have its price and quantity."
        >
          <AddOnForm
            nameSpace="variation"
            salesEndNamespace="variation"
            quantityNamespace="variation"
          />
        </FormWrapper>
      </Box>
    </Layer>
  ) : null;
}

AddVariation.propTypes = {};

export default AddVariation;
