import React from 'react';
import StatusCard from 'components/StatusCard';
import { Box, Button, Spinner, Text } from 'grommet';
import useDisclosure from '../../hooks/useDisclosure';
import noTicketsImg from '../../assets/noTickets.png';
import CreateTicketsDrawer from './CreateTicketsDrawer';
import { useQuery } from '@apollo/client';
import { EventsService, ITicketType, ITicketDistribution } from 'services/events';
import TicketCard from 'components/TicketCard';

function TicketList({ eventId }: { eventId: string }) {
  const { isOpen, onClose, onOpen } = useDisclosure({});
  const [selected, setSelected] = React.useState(null);
  const { loading, data } = useQuery<{
    ticket_types: ITicketType[];
    ticket_distribution: ITicketDistribution[];
  }>(EventsService.getEventsTickets(eventId));

  const handleOnClose = () => {
    if (selected) setSelected(null);
    onClose();
  };

  return (
    <Box>
      {loading ? (
        <Box height={{ min: '200px' }} justify="center" align="center">
          <Spinner />
          <Text textAlign="center">Fetching Members Info</Text>
        </Box>
      ) : !!!data?.ticket_types?.length ? (
        <StatusCard
          image={noTicketsImg}
          mainText="Every great event needs tickets"
          subText="You haven't created any tickets.
          To let people signup for your event click create ticket below."
        >
          <Button
            style={{ height: '48px' }}
            margin={{ top: '24px' }}
            primary
            label="Add tickets"
            onClick={onOpen}
          />
        </StatusCard>
      ) : (
        <Box>
          <Box>LIST OF CARDS</Box>
          {data.ticket_types?.map((ticket) => (
            <TicketCard
              key={ticket?.id}
              ticket={ticket}
              distributions={data?.ticket_distribution}
            />
          ))}
        </Box>
      )}
      <CreateTicketsDrawer
        isOpen={isOpen}
        onClose={handleOnClose}
        initialValues={selected}
        isEdit={!!selected}
        onSubmit={() => {}}
        // onChange={selected ? handleChange : undefined}
      />
    </Box>
  );
}

TicketList.propTypes = {};

export default TicketList;
